import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';
import { Typography, Image, Icon } from 'modules/common';
import { buildUrlParams } from 'core/login';

import './candidate-drawer.scss';

export function CandidatesDrawerCandidate({
  candidate,
  onCandidateClick,
  isSelectedCandidate = false,
  isClickable = true,
}) {
  const candidateAvatarUrl = candidate?.avatar?.image?.thumbnailUrl ?? defaultCandidateAvatar;
  const onClick = event => onCandidateClick(event, candidate);

  const notClickable = !isClickable || isSelectedCandidate;
  const Container = notClickable ? 'div' : Link;

  const hasAgencyUid = candidate.isAgencyCandidate || candidate.isAgency || candidate.is_revv_share;

  const params = buildUrlParams({
    isRevvShare: !!candidate.is_revv_share,
    isAgency: !!candidate.isAgency,
    agencyRevvUid: hasAgencyUid ? candidate.agencyRevvUid || candidate.organization_revv_uid : null,
    isAgencyCandidate: !!candidate.isAgencyCandidate,
  });

  const url = `/${candidate.organization_revv_uid}?${params}`;

  return (
    <Container
      to={notClickable ? null : url}
      onClick={notClickable ? null : onClick}
      onKeyPress={notClickable ? null : onClick}
      className={classnames('flex items-center no-underline', {
        'selected-candidate c--white': isSelectedCandidate,
        'unselected-candidate': !isSelectedCandidate,
        'reset-cursor': notClickable,
      })}
    >
      {candidate.isAgencyCandidate ? <Icon className="w-2 h-2" icon="square-full" color="gray" size="xs" /> : null}
      <div className={classnames('d-flex align-items-center pl-2 py-2', { 'pl-3': candidate.isAgencyCandidate })}>
        <div className="flex-shrink-0 px-2">
          <Image className="h-8 w-8 rounded-full object-cover" alt="Committee logo" src={candidateAvatarUrl} />
        </div>
        <Typography className="mb-0 ml-2" variant={candidate.isAgencyCandidate ? null : 'bodyMedium'}>
          {candidate?.name || ''}
        </Typography>
      </div>
    </Container>
  );
}
