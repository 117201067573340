import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';

import { isOrganizationSelector } from 'core/login';
import { GET_ALL_CANDIDATES_QUERY, GET_ALL_ORGANIZATION_CANDIDATES_QUERY } from 'core/middleware/queries';
import { ConnectedCommitteeList } from 'modules/pages/common/addCommittee/committee-list.component';

function UpsellCommittee({
  addCommitteeButtonDescription,
  addCommitteeDescription,
  className,
  selectedCandidates,
  disabled,
  committeeHasAmounts,
  splitEvenly,
  isOrganization,
  onChange,
  searchTitle,
  showConduitingAndDataSharingToggle,
}) {
  const GET_COMMITTEES_QUERY = isOrganization ? GET_ALL_ORGANIZATION_CANDIDATES_QUERY : GET_ALL_CANDIDATES_QUERY;

  return (
    <ConnectedCommitteeList
      eventName="candidates"
      onChange={onChange}
      className={className}
      disabled={disabled}
      selectedCandidates={selectedCandidates}
      committeeHasAmounts={committeeHasAmounts}
      addCommitteeDescription={addCommitteeDescription}
      addCommitteeButtonDescription={addCommitteeButtonDescription}
      searchTitle={searchTitle}
      splitEvenly={splitEvenly}
      splitEvenlyName="split_evenly"
      showConduitingAndDataSharingToggle={showConduitingAndDataSharingToggle}
      GET_COMMITTEES_QUERY={GET_COMMITTEES_QUERY}
    />
  );
}

UpsellCommittee.propTypes = {
  addCommitteeButtonDescription: PropTypes.string,
  addCommitteeDescription: PropTypes.string,
  className: PropTypes.string,
  committeeHasAmounts: PropTypes.bool,
  disabled: PropTypes.bool,
  splitEvenly: PropTypes.bool,
  isOrganization: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  searchTitle: PropTypes.string,
  selectedCandidates: PropTypes.array.isRequired,
  showConduitingAndDataSharingToggle: PropTypes.bool,
};

UpsellCommittee.defaultProps = {
  addCommitteeDescription: `You can split donations that are made through WinRed pages with other committees. If you have chosen to customize the default split percentage, candidates will be ordered by percentage weight on the landing page. To add a committee to this page, click "Add committee" and search for the committee by name.`,
  addCommitteeButtonDescription: `Add committees to your upsell.`,
  disabled: false,
  searchTitle: 'Search for a committee below to add it to this upsell.',
  splitEvenly: false,
  showConduitingAndDataSharingToggle: true,
};

const mapStateToProps = state => ({
  isOrganization: isOrganizationSelector(state),
});

export const ConnectedUpsellCommittee = compose(connect(mapStateToProps))(UpsellCommittee);
