import { cloneDeep } from 'lodash';

import uuid from 'core/utilities/uuid';
import { formatWidgetsForUi } from 'modules/common/widgets/tools';
import { convertCandidatesForUi, disableUpsell, hasConduitVendors } from 'modules/pages/pages/page.tools';
import { PANEL_UPSELLS_MAP, generateDefaultAmounts } from './drawer/new-upsell/sections/panel-upsell/tools';

import {
  CommitteeUpsell,
  SlateUpsell,
  SingleUpsell,
  CustomUpsell,
  DoubleUpsell,
  MobileUpsell,
  PanelUpsell,
  RecurringUpsell,
  VolunteerUpsell,
  MultipleUpsell,
  CreateUpsell,
  MoneyPledgeUpsell,
  ProductUpsell,
  MerchandiseUpsell,
  MatchProUpsell,
  SubscribableUpsell,
  MembershipUpsell,
} from 'modules/pages/upsells/drawer/new-upsell';
import { formatUpsellDropdown } from './format.tools';

/**
 * when an upsell is part of an upsell group it's an object with an upsell page
 */
export const formatUpsellPagesForUi = (upsell, disabledUpsellFlow = false, profileCandidate) => {
  let formattedUpsell = {
    ...upsell,
    upsell_page: { ...upsell.upsell_page, _isDisabled: disabledUpsellFlow || disableUpsell(upsell, profileCandidate) },
  };

  formattedUpsell.upsell_page = formatUpsellForUi(formattedUpsell.upsell_page, profileCandidate);
  return formattedUpsell;
};

/**
 * this is when an upsell is independent - its just an upsell object
 */
export const formatUpsellForUi = (upsell, profileCandidate) => {
  upsell = { ...upsell };

  if (upsell.type === PanelUpsell.type) {
    upsell = formatPanelUpsellForUi(upsell);
  }

  if (upsell.type === SlateUpsell.type) {
    upsell = formatSlateUpsellForUi(upsell);
  }

  if (upsell.type === SlateUpsell.type || upsell.type === CommitteeUpsell.type) {
    upsell = formatCandidatesForUi(upsell);
  }

  if (upsell.widgets?.length > 0) {
    upsell.widgets = formatWidgetsForUi(upsell.widgets);
  }

  // upsell may have ben disabled from formatting (ie preset upsell card)
  upsell._isDisabled = upsell._isDisabled || disableUpsell(upsell, profileCandidate);

  // see if upsell should show billpay info
  upsell._conduitsHaveVendors = hasConduitVendors(upsell.candidates || upsell.conduit_page_candidates);

  // do any of the child cards have hasConduitVendors set to true?
  upsell._conduitsHaveVendors =
    upsell._conduitsHaveVendors || !!upsell.cards?.find(upsellCard => upsellCard._conduitsHaveVendors);

  return upsell;
};

const formatCandidatesForUi = upsellPage => {
  upsellPage.candidates = (upsellPage.conduit_page_candidates || [])
    .concat(upsellPage.conduit_page_organizations || [])
    .map(c => {
      const orgId = c.candidate?.revv_uid || c.organization?.revv_uid;

      return {
        ...c,
        _isStateLevel: orgId?.startsWith('rv_org_'),
      };
    });

  return upsellPage;
};

const formatSlateUpsellForUi = upsellPage => {
  upsellPage.page_amounts = formatMultiplierAmounts(upsellPage);
  // Slate upsells have a show_other_amount_field flag instead of a include_other_field flag.
  if (upsellPage.type === SlateUpsell.type) {
    upsellPage.include_other_field = upsellPage.show_other_amount_field;
  }
  // Parse the upsell page candidates.
  const { conduitPageCandidates } = convertCandidatesForUi({
    conduitPageCandidates: upsellPage.conduit_page_candidates,
    conduitPageOrganizations: upsellPage.conduit_page_organizations,
  });

  upsellPage.conduit_page_candidates = conduitPageCandidates;
  delete upsellPage.conduit_page_organizations;

  return upsellPage;
};

/**
 * upsells within upsells need formatting
 */
export const formatPanelUpsellForUi = upsellPage => {
  // if api comes back with 0 we this means no cards are locked but we want the ui to show up as blank
  // eslint-disable-next-line eqeqeq
  if (upsellPage.locked_cards == 0) {
    upsellPage.locked_cards = null;
  }

  upsellPage.cards = upsellPage.cards.map(card => formatPanelUpsellCardForUi(card, upsellPage));
  return upsellPage;
};

/**
 * these are the upsells within upsells
 */
export const formatPanelUpsellCardForUi = (card, upsellPage) => {
  // dont format if already formatted or is a new card (not from the api)
  if (card.baseCardId || card._isNew) return card;

  const metadata = PANEL_UPSELLS_MAP[card.card.type] || {};

  let upsellCard = {
    baseCardId: card.id, // id of the base upsell card
    upsellCardId: card.card.id, // id of the copied upsell card attached to this upsell from the base
    position: card.position,

    // metadata for UI
    upsellComponent: metadata.upsellComponent,
    generateSubtitle: metadata.generateSubtitle,
    title: metadata.title || '',
    subtitle: metadata.subtitle || '',

    ...card.card,
    _isPreset: card.card.preset_panel_card,
    _isDisabled: card.card.preset_panel_card || upsellPage._isDisabled,
  };

  // slate cards need to be formatted for the UI
  if (upsellCard.type === CommitteeUpsell.type) {
    upsellCard = formatSlateUpsellForUi(upsellCard, true);
  }

  if (upsellCard.type === SlateUpsell.type || upsellCard.type === CommitteeUpsell.type) {
    upsellCard = formatCandidatesForUi(upsellCard);
  }

  // see if upsell should show billpay info
  upsellCard._conduitsHaveVendors = hasConduitVendors(upsellCard.candidates || upsellCard.conduit_page_candidates);

  return upsellCard;
};

export const formatPresetPanelUpsellCard = (selectedPresetCard, upsellCards) => {
  // dont format if already formatted or is a new card (not from the api)
  if (selectedPresetCard.baseCardId || selectedPresetCard._isNew) return selectedPresetCard;

  const metadata = PANEL_UPSELLS_MAP[selectedPresetCard.type] || {};

  let upsellCard = {
    _isNew: true,
    _isPreset: true,
    _isDisabled: true,
    baseCardId: selectedPresetCard.id, // id of the base upsell card
    id: selectedPresetCard.id, // id of the base upsell card
    // upsellCardId: card.card.id, // id of the copied upsell card attached to this upsell from the base
    position: upsellCards.length,

    // metadata for UI
    upsellComponent: metadata.upsellComponent,
    generateSubtitle: metadata.generateSubtitle,
    title: metadata.title || '',
    subtitle: metadata.subtitle || '',

    ...selectedPresetCard,
  };

  // slate cards need to be formatted for the UI
  if (upsellCard.type === CommitteeUpsell.type) {
    upsellCard = formatSlateUpsellForUi(upsellCard, true);
  }

  // slate cards need amounts formatted for multipliers
  if (upsellCard.type === CommitteeUpsell.type) {
    upsellCard.page_amounts = formatMultiplierAmounts(upsellCard);
  }

  if (upsellCard.type === SlateUpsell.type || upsellCard.type === CommitteeUpsell.type) {
    upsellCard = formatCandidatesForUi(upsellCard);
  }

  return upsellCard;
};

/**
 *
 */
export const formatMultiplierAmounts = upsell => {
  // slate cards need amounts formatted for multipliers
  let pageAmounts = (upsell.page_amounts = [
    ...upsell.page_amounts.map(amt => {
      return {
        ...amt,
        _isMultiplier: upsell.multiplier_amounts,
      };
    }),
    ...generateDefaultAmounts(upsell.include_other_field, !upsell.multiplier_amounts),
  ]);

  return pageAmounts;
};

export const formatUpsellGroupForServer = (page, removedExistingUpsellGroupData) => {
  const newPage = cloneDeep(page);
  /* Define a list of fields to remove when sending all the information to the
  server. */
  const fieldsToRemove = [
    'created_at',
    'updated_at',
    'revv_uid',
    'upsell_count',
    'page_count',
    'upsell_group_statistics',
    '_isNew',
    'upsell_pages',
    '__typename',
    '_isDisabled',
    'upsell_creator',
    'upsell_creator_revv_uid',
    'public_upsell_group',
    'bill_pay_exists',
    '_newOrExisting',
  ];

  // If the group doesn't exist, remove the revv_uid.
  if (newPage.upsellPageGroup._isNew) {
    delete newPage.upsellPageGroup.revv_uid;
    delete newPage.upsellPageGroup.revvUid;
    // If the group already exists, setup the revv_uid.
  } else {
    if (newPage.upsellPageGroup.revv_uid) {
      newPage.upsellPageGroup.revvUid = `${newPage.upsellPageGroup.revv_uid}`;
    }
    // if both a new and existing upsell flow were removed, we need to destroy the originally deleted one
    if (!newPage.upsellPageGroup.revv_uid && newPage.upsellPageGroup.destroy && removedExistingUpsellGroupData) {
      newPage.upsellPageGroup = {
        revvUid: removedExistingUpsellGroupData.revv_uid,
        name: removedExistingUpsellGroupData.name,
        destroy: true,
      };
      return newPage;
    }
  }

  newPage.upsellPageGroup.campaignRevvUid = newPage.upsellPageGroup?.campaign?.revv_uid ?? null;
  delete newPage.upsellPageGroup?.campaign;
  newPage.upsellPageGroup.updatedAt = newPage.upsellPageGroup.updated_at;

  fieldsToRemove.map(key => delete newPage.upsellPageGroup[key]);

  // Send the upsell pages info based on page ID and position.
  newPage.upsellPageGroup.upsellPages = (page.upsellPageGroup.upsell_pages || []).map((p, idx) => {
    const page = {
      position: idx + 1,
      upsellPageId: p.upsell_page.id,
      destroy: p._destroy ? true : false,
    };
    // If the page already exists, send the id.
    if (!p._isNew) page.id = p.id;
    return page;
  });
  return newPage;
};

export const cantSaveUpsellGroup = newState => {
  if (!newState.upsell_pages || newState.upsell_pages.length === 0) return true;
  return false;
};

/**
 * this is all disabled for now because the validator form should
 * automatically trigger errors and not continue the save api call
 * @param {*} upsell
 */

export const getUpsellDropdownOptions = (allowedOptions, inputValue) => {
  // [ch18348] If the list isn't filtered, return all of the options.
  if (!inputValue) return allowedOptions;
  const inputValueLower = inputValue.toLowerCase();
  return allowedOptions.filter(option => (option.label || '').toLowerCase().includes(inputValueLower));
};

export const DEFAULT_UPSELL = {
  name: '',
  type: null,
  paragraph: '',
  accept: 'Sign up',
  decline: 'Decline',
  geographical_sort: false,
  split_evenly: false,
  include_other_field: false,
  video_embed: '',
  enhanced: false,
  page_amounts: null,
};

export const DEFAULT_PAGE_AMOUNT = {
  amount: null,
  description: '',
};

/**
 * default values for a candidate's amounts (amount and amount 2)
 * this is for committee upsell
 */
export const generateCandidateAmounts = () => {
  return [
    { ...DEFAULT_PAGE_AMOUNT, _isNew: true, amount: 25 },
    { ...DEFAULT_PAGE_AMOUNT, _isNew: true, amount: 50 },
  ];
};

export const generateNewUpsell = ({
  upsellType,
  maxDonation,
  profileCandidate,
  availablePacCandidates,
  enableDonorCoverFees,
}) => {
  let newUpsell = {
    ...DEFAULT_UPSELL,
    _meta: {},
    type: upsellType,
    widgets: [],
    page_amounts: [],
  };

  if (newUpsell.type === DoubleUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Double Upsell',
      paragraph:
        '<p><span style="font-size: 14pt;"><strong>Double your impact!</strong></span></p><p>Thanks for your donation. Boost your support by doubling it!</p>',
      accept: 'Double my donation!',
    };
  } else if (newUpsell.type === RecurringUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Recurring Upsell',
      paragraph:
        '<p><span style="font-size: 14pt;"><strong>Make it monthly!</strong></span></p><p>Thanks for your donation! Now, take the next step and make your donation recur on a monthly basis.</p><p>Set up a monthly recurring donation now!</p>',
      accept: 'Make my donation recurring!',
    };
  } else if (newUpsell.type === CreateUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Create Account Upsell',
      paragraph: `<p><span style="font-size: 14pt;"><strong>Join Team WinRed!</strong></span></p><p>Create an account to join WinRed, the #1 grassroots fundraising network for conservatives.</p><p>Using your account, you can give to your favorite candidates and causes in one click and even raise money for them.</p><p>Signing up is easy and free -- just enter a password below.</p>`,
    };
  } else if (newUpsell.type === SlateUpsell.type) {
    const defaultCandidates = generateUpsellDefaultCandidate({
      profileCandidate,
      availablePacCandidates: availablePacCandidates.results,
    });

    newUpsell = {
      ...newUpsell,
      name: 'Donation Upsell',
      paragraph:
        '<p><span style="font-size: 14pt; color:#000000;"><strong>Donate to our friends!</strong></span></p><p><span style="color:#000000";">Thanks for your donation. Please donate to some other committees who count on your support.</span></p>',
      accept: 'Donate',
      split_evenly: true,
      page_amounts: [
        ...generateDefaultAmounts(newUpsell.include_other_field, true),
        ...generateDefaultAmounts(newUpsell.include_other_field, false),
      ],
      multiplier_amounts: true,
      minimum_amount: 1,
      maximum_amount: maxDonation,
      round_values: true,
      // [ch18347] Candidates should automatically be added to their own conduit upsells.
      candidates: defaultCandidates,
      donor_cover_fees: enableDonorCoverFees,
      recurring_callout: '',
      recurring_available: false,
      recurring_enable_radio_buttons: false,
    };
  } else if (newUpsell.type === VolunteerUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Volunteer Upsell',
      paragraph:
        '<p><span style="font-size: 14pt;"><strong>Get involved.</strong></span></p><p>Thanks for your donation! We count on people like you to mobilize our grassroots support.</p><p>Would you be willing to volunteer for us?</p>',
    };
  } else if (newUpsell.type === MobileUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Mobile Opt-in Upsell',
      paragraph:
        '<p><span style="font-size: 14pt;"><strong>Stay informed.</strong></span></p><p>Thanks for your donation! Sign up for SMS messages to get our latest updates.</p>',
    };
  } else if (newUpsell.type === CustomUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Custom Content Upsell',
      paragraph:
        '<p><span style="font-size: 14pt;"><strong>Thank you!</strong></span></p><p>Thanks for your donation! Watch our latest video.</p>',
      accept: 'Continue',
    };
  } else if (newUpsell.type === SingleUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Single Amount Upsell',
      paragraph:
        '<p><span style="font-size: 14pt;"><strong>Make an additional donation!</strong></span></p><p>Thanks for your donation. Please consider making an additional donation.',
      accept: 'Donate',
      page_amounts: [{ amount: 25, description: '', _isNew: true, id: uuid() }],
    };
  } else if (newUpsell.type === MultipleUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Multiple Amounts Upsell',
      paragraph:
        '<p><span style="font-size: 14pt;"><strong>Make an additional donation!</strong></span></p><p>Thanks for your donation. Please consider making an additional donation.',
      accept: 'Donate',
      page_amounts: [
        ...generateDefaultAmounts(newUpsell.include_other_field, true),
        ...generateDefaultAmounts(newUpsell.include_other_field, false),
      ],
    };
  } else if (newUpsell.type === MoneyPledgeUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Money Pledge Upsell',
      accept: 'Join the Money Pledge!',
      paragraph: '<p>Thanks for your donation. Can you join our Money Pledge?',
    };
  } else if (newUpsell.type === CommitteeUpsell.type) {
    newUpsell = {
      ...newUpsell,
      name: 'Committee Panel Upsell',
      paragraph:
        '<p><span style="font-size: 14pt; color:#000000;"><strong>Donate to our friends!</strong></span></p><p><span style="color:#000000;">Thanks for your donation. Please donate to some other committees who count on your support.</span></p>',
    };
  } else if (newUpsell.type === PanelUpsell.type) {
    // add this as an internal prop so we can attach to panel cards if needed
    const defaultCandidates = generateUpsellDefaultCandidate({
      profileCandidate,
      availablePacCandidates: availablePacCandidates.results,
    });

    newUpsell = {
      ...newUpsell,
      name: 'Panel Upsell',
      randomized_sort: false,
      locked_cards: null,
      cards: [],
      _defaultCandidates: defaultCandidates,
      paragraph:
        '<p><span style="color: #000000;">Thanks for your donation! Here are additional actions you can take to show your support!</span></p>',
    };
  }

  return addUpsellMetaData(newUpsell);
};

// generate the default candidate added to an upsell (need vendor fees attached to candidate)
// convert from pac_candiodate -> conduit_candidate format
export const generateUpsellDefaultCandidate = ({ profileCandidate, availablePacCandidates }) => {
  const isOrganization = profileCandidate.revv_uid.startsWith('rv_org_');
  let defaultCandidates = [];

  if (
    (!isOrganization || profileCandidate.state_level_splits_active) &&
    availablePacCandidates &&
    availablePacCandidates[0]
  ) {
    defaultCandidates = [
      {
        ...availablePacCandidates[0],
        candidate: {
          revv_uid: availablePacCandidates[0].revv_uid,
          name: availablePacCandidates[0].name,
          district: availablePacCandidates[0].district,
          office: availablePacCandidates[0].office,
          state: availablePacCandidates[0].state,
          available_vendors: availablePacCandidates[0].available_vendors ?? [],
          avatar: availablePacCandidates[0].avatar,
          can_access_vendors: availablePacCandidates[0].can_access_vendors || false,
        },
        vendorFees: [],
        _destroy: false,
        _isNew: true,
        _isStateLevel: profileCandidate.revv_uid.startsWith('rv_org_'),
        allowDataSharing: true,
        id: 0,
      },
    ];
  }

  return defaultCandidates;
};

/**
 * adds meta data needed to keep track of upsell form save button disabling
 * as well as some form meta data
 */
export const addUpsellMetaData = upsell => {
  switch (upsell.type) {
    case DoubleUpsell.type:
      upsell._meta = {
        paragraphRequired: true,
        acceptRequired: true,
      };
      break;
    case RecurringUpsell.type:
      upsell._meta = {
        paragraphRequired: true,
        acceptRequired: true,
      };
      break;
    case CreateUpsell.type:
      upsell._meta = {
        paragraphRequired: true,
        acceptRequired: true,
        declineRequired: true,
      };
      break;
    case SlateUpsell.type:
      upsell._meta = {
        pageAmountsRequired: true,
        addCommitteesRequired: true,
        paragraphRequired: true,
        acceptRequired: true,
      };
      break;
    case VolunteerUpsell.type:
      upsell._meta = {
        paragraphRequired: true,
        acceptRequired: true,
        declineRequired: true,
      };
      break;
    case MobileUpsell.type:
      upsell._meta = {
        paragraphRequired: true,
        acceptRequired: true,
        declineRequired: true,
      };
      break;
    case CustomUpsell.type:
      upsell._meta = {
        paragraphRequired: true,
        acceptRequired: true,
      };
      break;
    case SingleUpsell.type:
      upsell._meta = {
        pageAmountsRequired: true,
        removePageAmountsDescription: true,
        paragraphRequired: true,
        acceptRequired: true,
        declineRequired: true,
      };
      break;
    case MultipleUpsell.type:
      upsell._meta = {
        pageAmountsRequired: true,
        removePageAmountsDescription: true,
        paragraphRequired: true,
        declineRequired: true,
      };
      break;
    case CommitteeUpsell.type:
      upsell._meta = {
        addCommitteesRequired: true,
        paragraphRequired: true,
        committeeAmounts: true,
      };
      break;
    case MoneyPledgeUpsell.type:
      upsell._meta = {
        paragraphRequired: true,
        acceptRequired: true,
      };
      break;
    case PanelUpsell.type:
      upsell._meta = {
        cardsRequired: true,
        paragraphRequired: true,
      };
      break;

    default:
      return { ...upsell, _meta: {} };
  }

  upsell._meta.videoEmbedRequired = true;
  return upsell;
};

export const DEFAULT_UPSELL_GROUP = {
  revv_uid: null,
  _isNew: true,
  name: '',
  upsell_count: 0,
  page_count: 0,
  upsell_group_statistics: {
    view: 0,
    accepted: 0,
    percentage: 0,
  },
  upsell_pages: [],
};

export const UPSELL_COMPONENT_MAP = {
  [CreateUpsell.type]: CreateUpsell,
  [CommitteeUpsell.type]: CommitteeUpsell,
  [CustomUpsell.type]: CustomUpsell,
  [DoubleUpsell.type]: DoubleUpsell,
  [MatchProUpsell.type]: MatchProUpsell,
  [MembershipUpsell.type]: MembershipUpsell,
  [MerchandiseUpsell.type]: MerchandiseUpsell,
  [MobileUpsell.type]: MobileUpsell,
  [MoneyPledgeUpsell.type]: MoneyPledgeUpsell,
  [MultipleUpsell.type]: MultipleUpsell,
  PANEL: PanelUpsell, // since this component is wrapped in a compose it returns null
  [ProductUpsell.type]: ProductUpsell,
  [RecurringUpsell.type]: RecurringUpsell,
  [SingleUpsell.type]: SingleUpsell,
  [SlateUpsell.type]: SlateUpsell,
  [SubscribableUpsell.type]: SubscribableUpsell,
  [VolunteerUpsell.type]: VolunteerUpsell,
};

export const createUpsellDropdowns = (data, profileCandidate) => {
  if (!data.viewer) return { data: { ...data, upsellDropdownOptions: [], upsellGroup: null } };
  const candidate = data.viewer && (data.viewer.candidate || data.viewer.state_level_organization);

  const upsellDropdownOptions = formatUpsellDropdown(candidate);

  const upsellGroup = { ...cloneDeep(DEFAULT_UPSELL_GROUP) };
  upsellGroup.upsell_pages = (upsellGroup.upsell_pages || []).map(upsell =>
    formatUpsellPagesForUi(upsell, null, profileCandidate)
  );

  return { data: { ...data, upsellDropdownOptions, upsellGroup } };
};

export const updateUpsell = (editedUpsell, name, value) => {
  if (name === 'upsell_pages') editedUpsell = value;
  else editedUpsell[name] = value;

  // update this internal prop so we can keep track so we know when to show various billpay info
  if (name === 'candidates') {
    editedUpsell._conduitsHaveVendors = hasConduitVendors(editedUpsell.candidates);
  }

  // check if any panel cards have vendors
  if (name === 'cards') {
    let cardHaveVendors = false;
    editedUpsell.cards = editedUpsell.cards.map(card => {
      card._conduitsHaveVendors = hasConduitVendors(card.candidates);
      if (card._conduitsHaveVendors) cardHaveVendors = true;
      return card;
    });

    editedUpsell._conduitsHaveVendors = hasConduitVendors(editedUpsell.candidates) || cardHaveVendors;
  }

  return editedUpsell;
};
