import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FormContainer, FormColumn, FormCard, Typography, WinRedInput, WinRedInputNumbers } from 'modules/common';
import { isAgencySelector, profileCandidateSelector } from 'core/login';
import { characterLimit, maxValue, minValue } from 'core/utilities';

import { CommitteeConnectFullAccess } from './committee-connect-full-access.component';
import { CommitteeConnectBillPayOnly } from './committee-connect-bill-pay-only.component';

import defaultCandidateAvatar from 'assets/images/thumb_shield-fallback.png';

const percentageValidators = [
  {
    message: 'Default percentage fee is required.',
    validator: 'required',
  },
  {
    message: 'Max value of 100 percent',
    validator: inputValue => maxValue(100, inputValue),
  },
  {
    message: 'Min value of 0 percent',
    validator: inputValue => minValue(0, inputValue),
  },
];

function CommitteConnectForm({ billPay, committee, loading, onChange, onSearchCommittee }) {
  const isAgency = useSelector(isAgencySelector);
  const profileCandidate = useSelector(profileCandidateSelector);

  const [timeoutId, setTimeoutId] = useState('');

  const validBillPayID = useCallback(() => {
    if (!billPay.billPayId || committee === undefined) return true;

    return committee;
  }, [committee, billPay]);

  const _setBillPayId = useCallback(
    ({ target: { value } }) => {
      if (timeoutId) clearTimeout(timeoutId);

      onChange({ target: { name: 'billPayId', value } });

      if (!value) return;

      const _id = setTimeout(() => {
        const variables = {};

        if (isAgency) {
          variables.billPayId = value;
          variables.vendorRevvUid = profileCandidate.organization_revv_uid;
        } else {
          variables.billPayId = value;
          variables.organizationRevvUid = profileCandidate.organization_revv_uid;
        }

        if (value && value.length > 25) {
          onSearchCommittee({ variables });
        }
      }, 400);

      setTimeoutId(_id);
    },
    [onSearchCommittee, isAgency, timeoutId, profileCandidate, onChange]
  );

  const validators = useMemo(
    () => [
      { validator: characterLimit, message: 'There is a 31 character limit for this field.' },
      {
        validator: validBillPayID,
        message: `This Bill-Pay ID belongs to another ${isAgency ? 'Vendor' : 'Committee'}. As a ${
          isAgency ? 'Vendor' : 'Committee'
        } you can only connect to ${isAgency ? 'Committees' : 'Vendors'}. Please check the Bill-Pay ID and re-enter.`,
      },
    ],
    [isAgency, validBillPayID]
  );

  const avatarUrl = committee?.avatar?.image?.thumbnailUrl || defaultCandidateAvatar;

  const showFullAccess = (isAgency && !profileCandidate.is_revv_share) || (!isAgency && !committee?.is_revv_share);

  const connectionTypeText = isAgency ? 'Committee' : 'Vendor';

  return (
    <FormContainer>
      <FormColumn>
        <FormCard
          classNameFooter="bg--catskill-white"
          subtitle={`Add a connection with a ${connectionTypeText} to your account via their Bill-Pay ID. You'll need to ask the ${connectionTypeText} for this.`}
          title="Bill-Pay ID*"
          footer={
            committee ? (
              <FormContainer className="flex-column">
                <header className="flex items-center mb-2">
                  <img className="h-8 w-8 rounded-full flex-none" alt="connection profile" src={avatarUrl} />

                  <Typography variant="h4" className="ml-4 grow">
                    {committee.name}
                  </Typography>
                </header>
                <div className="mt-3">
                  <Typography className="mb-1" variant="bodyMedium">
                    Default Percentage Fee
                  </Typography>
                  <div className="md:flex">
                    <div className="flex-1">
                      <WinRedInputNumbers
                        description="This default percentage fee can be changed by either party once the connection is complete."
                        name="defaultFeePercentage"
                        append={'%'}
                        placeholder="Default Percentage Fee"
                        value={billPay.defaultFeePercentage}
                        className="winred-input input-with-append"
                        onChange={onChange}
                        doNotWrap={true}
                        validators={percentageValidators}
                        percentageValue={true}
                      />
                    </div>
                  </div>
                </div>
                {showFullAccess ? (
                  <CommitteeConnectFullAccess committee={isAgency ? profileCandidate : committee} />
                ) : (
                  <CommitteeConnectBillPayOnly committee={isAgency ? profileCandidate : committee} />
                )}
              </FormContainer>
            ) : null
          }
        >
          <div className="mb-3">
            <WinRedInput
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              disabled={loading}
              maxLength={35}
              name="billPayId"
              placeholder="Bill-Pay ID"
              validators={validators}
              type="search"
              value={billPay.billPayId}
              onChange={_setBillPayId}
            />
          </div>
        </FormCard>
      </FormColumn>
    </FormContainer>
  );
}

CommitteConnectForm.propTypes = {
  billPay: PropTypes.object.isRequired,
  committee: PropTypes.object,
  loading: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  onSearchCommittee: PropTypes.func.isRequired,
};

export { CommitteConnectForm };
