import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { Card, Typography } from 'modules/common';
import { formatNumberWithCommas } from 'core/utilities';

function FlowStats({ editedUpsellGroup }) {
  if (editedUpsellGroup?.public_upsell_group) return null;
  const { upsell_group_statistics = {}, upsell_count, page_count } = editedUpsellGroup;

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title subtitle="Stats for your upsell flow as a whole.">Upsell Flow Stats</Card.Title>
        <Row>
          <Col xs={6} className="mb-3">
            <Typography className="text-uppercase mb-0">viewed</Typography>
            <Typography variant="h5">{formatNumberWithCommas(upsell_group_statistics?.view)}</Typography>
          </Col>
          <Col xs={6} className="mb-3">
            <Typography className="text-uppercase mb-0">accepted</Typography>
            <Typography variant="h5">{formatNumberWithCommas(upsell_group_statistics?.accepted)}</Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className="mb-3">
            <Typography className="text-uppercase mb-0">opt-in rate</Typography>
            <Typography variant="h5">{(upsell_group_statistics?.percentage * 100)?.toFixed(0) || 0}%</Typography>
          </Col>
          <Col xs={6} className="mb-3">
            <Typography className="text-uppercase mb-0">pages</Typography>
            <Typography variant="h5">{formatNumberWithCommas(page_count)}</Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Typography className="text-uppercase mb-0">upsells</Typography>
            <Typography className="mb-0" variant="h5">
              {upsell_count || 0}
            </Typography>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

FlowStats.propTypes = {
  editedUpsellGroup: PropTypes.object.isRequired,
};

export default FlowStats;
